import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isAuthenticated } from '../helpers/auth-helper'
import { MainLayout } from '../layouts/main-layout'
import { NonLoginLayout } from '../layouts/non-login-layout'

export const AuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export const NonAuthRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <NonLoginLayout>
          <Component {...props} />
        </NonLoginLayout>
      )}
    />
  )
}
