import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,
  GET_USER_FEE_ERROR,
  GET_USER_FEE_START,
  GET_USER_FEE_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
} from '../actions'

export const loginUser = (user, history, from) => ({
  type: LOGIN_USER,
  payload: { user, history, from },
})
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
})
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
})

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
})
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
})
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
})

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
  type: RESET_PASSWORD,
  payload: { resetPasswordCode, newPassword, history },
})
export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword,
})
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
})

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
})
export const registerUserSuccess = (message) => ({
  type: REGISTER_USER_SUCCESS,
  payload: { message },
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
})

export const getProfileStart = () => ({
  type: GET_PROFILE_START,
})
export const getProfileSuccess = (profile) => ({
  type: GET_PROFILE_SUCCESS,
  payload: { profile },
})
export const getProfileError = (message) => ({
  type: GET_PROFILE_ERROR,
  payload: { message },
})

export const updateProfileStart = (profile) => ({
  type: UPDATE_PROFILE_START,
  payload: { profile },
})
export const updateProfileSuccess = (message) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: { message },
})
export const updateProfileError = (message) => ({
  type: UPDATE_PROFILE_ERROR,
  payload: { message },
})

export const updatePasswordStart = (passwordData, history) => ({
  type: UPDATE_PASSWORD_START,
  payload: { passwordData, history },
})
export const updatePasswordSuccess = (message) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: { message },
})
export const updatePasswordError = (error) => ({
  type: UPDATE_PASSWORD_ERROR,
  payload: { error },
})

export const getUserFeeStart = () => ({
  type: GET_USER_FEE_START,
})
export const getUserFeeSuccess = (fees) => ({
  type: GET_USER_FEE_SUCCESS,
  payload: { fees },
})
export const getUserFeeError = (error) => ({
  type: GET_USER_FEE_ERROR,
  payload: { error },
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
})
