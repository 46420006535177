import {
  GET_BUY_ORDER_DETAIL_ERROR,
  GET_BUY_ORDER_DETAIL_START,
  GET_BUY_ORDER_DETAIL_SUCCESS,
  GET_BUY_ORDERS_ERROR,
  GET_BUY_ORDERS_START,
  GET_BUY_ORDERS_SUCCESS,
  GET_SHIP_ORDER_DETAIL_ERROR,
  GET_SHIP_ORDER_DETAIL_START,
  GET_SHIP_ORDER_DETAIL_SUCCESS,
  GET_SHIP_ORDERS_ERROR,
  GET_SHIP_ORDERS_START,
  GET_SHIP_ORDERS_SUCCESS,
} from '../actions'

/**
 * Get Buy Orders
 */
export const getBuyOrdersStart = () => ({
  type: GET_BUY_ORDERS_START,
})
export const getBuyOrdersSuccess = (buyOrders) => ({
  type: GET_BUY_ORDERS_SUCCESS,
  payload: { buyOrders },
})
export const getBuyOrdersError = (error) => ({
  type: GET_BUY_ORDERS_ERROR,
  payload: { error },
})

/**
 * Get Buy Order Detail By Id
 */
export const getBuyOrderDetailStart = (id) => ({
  type: GET_BUY_ORDER_DETAIL_START,
  payload: { id },
})
export const getBuyOrderDetailSuccess = (buyOrder) => ({
  type: GET_BUY_ORDER_DETAIL_SUCCESS,
  payload: { buyOrder },
})
export const getBuyOrderDetailError = (error) => ({
  type: GET_BUY_ORDER_DETAIL_ERROR,
  payload: { error },
})

/**
 * Get Ship Orders
 */
export const getShipOrdersStart = () => ({
  type: GET_SHIP_ORDERS_START,
})
export const getShipOrdersSuccess = (shipOrders) => ({
  type: GET_SHIP_ORDERS_SUCCESS,
  payload: { shipOrders },
})
export const getShipOrdersError = (error) => ({
  type: GET_SHIP_ORDERS_ERROR,
  payload: { error },
})

/**
 * Get Ship Order Detail By Id
 */
export const getShipOrderDetailStart = (id) => ({
  type: GET_SHIP_ORDER_DETAIL_START,
  payload: { id },
})
export const getShipOrderDetailSuccess = (shipOrder) => ({
  type: GET_SHIP_ORDER_DETAIL_SUCCESS,
  payload: { shipOrder },
})
export const getShipOrderDetailError = (error) => ({
  type: GET_SHIP_ORDER_DETAIL_ERROR,
  payload: { error },
})
