import {
  ADD_ORDER_ERROR,
  ADD_ORDER_START,
  ADD_ORDER_SUCCESS,
  ADD_SHIP_ORDER_ERROR,
  ADD_SHIP_ORDER_START,
  ADD_SHIP_ORDER_SUCCESS,
  ADD_TO_CART,
  CLEAR_TO_CART,
  RESET_ADD_ORDER,
  SAVE_TO_CART,
} from '../actions'
import { calculateFee } from '../../helpers/utils'

const INIT_STATE = {
  cartBuyOrder: {
    branch: '',
    address: '',
    items: [],
    quickDelivery: 'normal',
    checkRequired: false,
    packageRequired: false,
    privateNote: '',
    totalPrice: 0,
    serviceFee: 0,
  },
  addOrder: {
    isLoading: false,
    message: '',
    error: '',
  },
  addShipOrder: {
    isLoading: false,
    message: '',
    error: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ORDER_START:
      return {
        ...state,
        addOrder: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        addOrder: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case ADD_ORDER_ERROR:
      return {
        ...state,
        addOrder: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    case RESET_ADD_ORDER:
      return {
        ...state,
        addOrder: {
          isLoading: false,
          message: '',
          error: '',
        },
      }

    case ADD_SHIP_ORDER_START:
      return {
        ...state,
        addShipOrder: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case ADD_SHIP_ORDER_SUCCESS:
      return {
        ...state,
        addShipOrder: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case ADD_SHIP_ORDER_ERROR:
      return {
        ...state,
        addShipOrder: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    case SAVE_TO_CART:
      let cartBuyOrder = action.payload.cartBuyOrder

      return {
        ...state,
        cartBuyOrder,
      }

    case ADD_TO_CART:
      let { items, customerFee } = action.payload
      let storeItems = state.cartBuyOrder.items
      let totalPrice = 0
      let serviceFee = 0
      let duplicatedIndex = []

      // Merge item if having same name, link, options and priceCNY
      storeItems = storeItems.map((storeItem) => {
        items.forEach((item, index) => {
          if (
            storeItem.name === item.name &&
            storeItem.link === item.link &&
            storeItem.options === item.options &&
            storeItem.priceCNY === item.priceCNY
          ) {
            storeItem.quantity += item.quantity
            duplicatedIndex.push(index)
          }
        })

        return storeItem
      })

      // Filter added items
      items = items.filter((item, index) => duplicatedIndex.indexOf(index) === -1)

      // Add store items with added items
      storeItems = storeItems.concat(items)

      // Calculate total price
      storeItems = storeItems.map((storeItem) => {
        storeItem.totalCNY = storeItem.priceCNY * storeItem.quantity
        storeItem.totalVND = Math.round(storeItem.priceVND * storeItem.quantity)
        totalPrice += storeItem.totalVND

        return storeItem
      })

      serviceFee = calculateFee(totalPrice, customerFee)

      return {
        ...state,
        cartBuyOrder: {
          ...state.cartBuyOrder,
          items: storeItems,
          serviceFee,
          totalPrice,
        },
      }

    case CLEAR_TO_CART:
      return { ...INIT_STATE }

    default:
      return state
  }
}
