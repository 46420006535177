import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  ACCEPT_COMPLAINT_START,
  ADD_NEW_COMPLAINT_START,
  GET_COMPLAINT_DETAIL_START,
  REJECT_COMPLAINT_START,
} from '../actions'
import {
  acceptComplaintSuccess,
  addNewComplaintError,
  addNewComplaintSuccess,
  getComplaintDetailError,
  getComplaintDetailSuccess,
  rejectComplaintSuccess,
} from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'

/**
 * Add New Complaint
 */
export function* watchAddNewComplaint() {
  yield takeEvery(ADD_NEW_COMPLAINT_START, addNewComplaint)
}

const addNewComplaintAsync = (complaint) => {
  return apiClient.post('/complaint/new', complaint)
}

function* addNewComplaint({ payload }) {
  const { complaint } = payload
  try {
    const response = yield call(addNewComplaintAsync, complaint)
    if (!response.data.status) {
      yield put(addNewComplaintError('Có lỗi xảy ra khi thêm mới khiếu nại.'))
      notification.danger('Có lỗi xảy ra khi thêm mới khiếu nại.')
    } else {
      yield put(addNewComplaintSuccess(response.data.data))
      notification.success('Đã gửi khiếu nại tới quản trị viên', 'Thành công!')
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(addNewComplaintError(error.message))
  }
}

/**
 * Get Complaint Detail
 */
export function* watchGetComplaintDetail() {
  yield takeEvery(GET_COMPLAINT_DETAIL_START, getComplaintDetail)
}

const getComplaintDetailAsync = (id) => {
  return apiClient.get(`/complaint/${id}`)
}

function* getComplaintDetail({ payload }) {
  const { id } = payload
  try {
    const response = yield call(getComplaintDetailAsync, id)
    if (!response.data.status) {
      yield put(getComplaintDetailError('Có lỗi xảy ra khi load chi tiết khiếu nại.'))
      notification.danger('Có lỗi xảy ra khi load chi tiết khiếu nại.')
    } else {
      yield put(getComplaintDetailSuccess(response.data.data))
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(getComplaintDetailError(error.message))
  }
}

/**
 * Accept Complaint
 */
export function* watchAcceptComplaint() {
  yield takeEvery(ACCEPT_COMPLAINT_START, acceptComplaint)
}

function* acceptComplaint({ payload }) {
  const { id } = payload
  try {
    const response = yield call(acceptComplaintAsync, id)
    if (!response.data.status) {
      yield put(getComplaintDetailError('Có lỗi xảy ra khi load chi tiết khiếu nại.'))
      notification.danger('Có lỗi xảy ra khi load chi tiết khiếu nại.')
    } else {
      yield put(acceptComplaintSuccess(id))
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(getComplaintDetailError(error))
  }
}

const acceptComplaintAsync = (id) => {
  return apiClient.post(`/complaint/${id}/accept`, {})
}

/**
 * Reject Complaint
 */
export function* watchRejectComplaint() {
  yield takeEvery(REJECT_COMPLAINT_START, rejectComplaint)
}

function* rejectComplaint({ payload }) {
  const { id } = payload
  try {
    const response = yield call(rejectComplaintAsync, id)
    if (!response.data.status) {
      yield put(getComplaintDetailError('Có lỗi xảy ra khi load chi tiết khiếu nại.'))
      notification.danger('Có lỗi xảy ra khi load chi tiết khiếu nại.')
    } else {
      yield put(rejectComplaintSuccess(id))
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(getComplaintDetailError(error))
  }
}

const rejectComplaintAsync = (id) => {
  return apiClient.post(`/complaint/${id}/reject`, {})
}

export default function* rootSaga() {
  yield all([
    fork(watchAddNewComplaint),
    fork(watchGetComplaintDetail),
    fork(watchAcceptComplaint),
    fork(watchRejectComplaint),
  ])
}
