import {
  ACCEPT_COMPLAINT_SUCCESS,
  ADD_NEW_COMPLAINT_ERROR,
  ADD_NEW_COMPLAINT_START,
  ADD_NEW_COMPLAINT_SUCCESS,
  GET_COMPLAINT_DETAIL_ERROR,
  GET_COMPLAINT_DETAIL_START,
  GET_COMPLAINT_DETAIL_SUCCESS,
  REJECT_COMPLAINT_SUCCESS,
} from '../actions'

const INIT_STATE = {
  addNewComplaint: {
    isLoading: false,
    data: null,
    error: '',
  },
  complaintDetail: {
    isLoading: false,
    data: null,
    error: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_COMPLAINT_START:
      return {
        ...state,
        addNewComplaint: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case ADD_NEW_COMPLAINT_SUCCESS:
      return {
        ...state,
        addNewComplaint: {
          isLoading: false,
          data: action.payload.complaint,
          error: '',
        },
      }

    case ADD_NEW_COMPLAINT_ERROR:
      return {
        ...state,
        addNewComplaint: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    case GET_COMPLAINT_DETAIL_START:
      return {
        ...state,
        complaintDetail: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case GET_COMPLAINT_DETAIL_SUCCESS:
      return {
        ...state,
        complaintDetail: {
          isLoading: false,
          data: action.payload.complaint,
          error: '',
        },
      }

    case GET_COMPLAINT_DETAIL_ERROR:
      return {
        ...state,
        complaintDetail: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    case ACCEPT_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaintDetail: {
          data: {
            ...state.complaintDetail.data,
            status: 'COMPLETED',
          },
        },
      }

    case REJECT_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaintDetail: {
          data: {
            ...state.complaintDetail.data,
            status: 'CANCELED',
          },
        },
      }

    default:
      return state
  }
}
