import { GET_PROVINCE_ERROR, GET_PROVINCE_START, GET_PROVINCE_SUCCESS } from '../actions'

const INIT_STATE = {
  isLoading: false,
  provinceItems: null,
  error: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROVINCE_START:
      return { ...state, isLoading: true }

    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        provinceItems: action.payload,
      }

    case GET_PROVINCE_ERROR:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return { ...state }
  }
}
