import { createSelector } from 'reselect'

const selectCart = (state) => state.cart
const selectCartAddOrder = (state) => state.cart.addOrder
const selectCartAddShipOrder = (state) => state.cart.addShipOrder

export const selectAddOrderIsLoading = createSelector([selectCartAddOrder], (addOrder) => addOrder.isLoading)

export const selectAddShipOrderIsLoading = createSelector(
  [selectCartAddShipOrder],
  (addShipOrder) => addShipOrder.isLoading
)

export const selectCartBuyOrder = createSelector([selectCart], (cart) => cart.cartBuyOrder)
