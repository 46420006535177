import axios from 'axios'
import store from '../redux/store'
import { createBrowserHistory } from 'history'
import { logoutUser } from '../redux/auth/actions'
import { isAuthenticated } from './auth-helper'

const history = createBrowserHistory()

// http://localhost:9000
// http://api.alimuaha.com

export const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API}/api`,
  timeout: 10000,
})

// Handle when access token expire.
apiClient.interceptors.request.use(
  function (config) {
    const token = isAuthenticated()

    if (token) {
      config.headers = config.headers || {}
      config.headers['Authorization'] = `JWT ${token}`
    }

    config.params = config.params || {}
    // TODO: Remove after testing
    // config.params['stub'] = 'true'

    return config
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

// Handle when access token expire.
apiClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.code === 500 && !response.data.status) {
      if (['jwt expired', 'jwt malformed', 'invalid token', 'invalid signature'].includes(response.data.message)) {
        store.dispatch(logoutUser(history))
      }
    }

    if (response.data.code === 403) {
      store.dispatch(logoutUser(history))
    }

    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
