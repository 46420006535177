import {
  ADD_ORDER_ERROR,
  ADD_ORDER_START,
  ADD_ORDER_SUCCESS,
  ADD_SHIP_ORDER_ERROR,
  ADD_SHIP_ORDER_START,
  ADD_SHIP_ORDER_SUCCESS,
  ADD_TO_CART,
  CLEAR_TO_CART,
  RESET_ADD_ORDER,
  SAVE_TO_CART,
} from '../actions'

export const addOrderStart = (order) => ({
  type: ADD_ORDER_START,
  payload: { order },
})
export const addOrderSuccess = (message) => ({
  type: ADD_ORDER_SUCCESS,
  payload: { message },
})
export const addOrderError = (error) => ({
  type: ADD_ORDER_ERROR,
  payload: { error },
})

export const addShipOrderStart = (shipOrder) => ({
  type: ADD_SHIP_ORDER_START,
  payload: { shipOrder },
})
export const addShipOrderSuccess = (message) => ({
  type: ADD_SHIP_ORDER_SUCCESS,
  payload: { message },
})
export const addShipOrderError = (error) => ({
  type: ADD_SHIP_ORDER_ERROR,
  payload: { error },
})

export const saveToCart = (cartBuyOrder) => ({
  type: SAVE_TO_CART,
  payload: { cartBuyOrder },
})

export const addToCart = (items, customerFee) => ({
  type: ADD_TO_CART,
  payload: { items, customerFee },
})

export const clearToCart = () => ({
  type: CLEAR_TO_CART,
})

export const resetAddOrder = () => ({
  type: RESET_ADD_ORDER,
})
