import { createSelector } from 'reselect'

const selectMenu = (state) => state.menu

export const selectContainerClassnames = createSelector([selectMenu], (menu) => menu.containerClassnames)

export const selectSubHiddenBreakpoint = createSelector([selectMenu], (menu) => menu.subHiddenBreakpoint)

export const selectMenuHiddenBreakpoint = createSelector([selectMenu], (menu) => menu.menuHiddenBreakpoint)

export const selectMenuClickCount = createSelector([selectMenu], (menu) => menu.menuClickCount)

export const selectSelectedMenuHasSubItems = createSelector([selectMenu], (menu) => menu.selectedMenuHasSubItems)
