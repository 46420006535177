/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const GET_PROFILE_START = 'GET_PROFILE_START'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'
export const GET_USER_FEE_START = 'GET_USER_FEE_START'
export const GET_USER_FEE_SUCCESS = 'GET_USER_FEE_SUCCESS'
export const GET_USER_FEE_ERROR = 'GET_USER_FEE_ERROR'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS'

/* PROVINCE */
export const GET_PROVINCE_START = 'GET_PROVINCE_START'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_SUCCESS'
export const GET_PROVINCE_ERROR = 'GET_PROVINCE_ERROR'

/* DISTRICT */
export const GET_DISTRICT_START = 'GET_DISTRICT_START'
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_SUCCESS'
export const GET_DISTRICT_ERROR = 'GET_DISTRICT_ERROR'

/* BRANCH */
export const GET_BRANCH_START = 'GET_BRANCH_START'
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS'
export const GET_BRANCH_ERROR = 'GET_BRANCH_ERROR'

/* CATEGORIES */
export const GET_CATEGORIES_START = 'GET_CATEGORIES_START'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'

/* ADDRESS */
export const GET_ADDRESS_START = 'GET_ADDRESS_START'
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS'
export const GET_ADDRESS_ERROR = 'GET_ADDRESS_ERROR'
export const DELETE_ADDRESS_START = 'DELETE_ADDRESS_START'
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_ERROR = 'DELETE_ADDRESS_ERROR'
export const ADD_ADDRESS_START = 'ADD_ADDRESS_START'
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS'
export const ADD_ADDRESS_ERROR = 'ADD_ADDRESS_ERROR'
export const EDIT_ADDRESS_START = 'EDIT_ADDRESS_START'
export const EDIT_ADDRESS_SUCCESS = 'EDIT_ADDRESS_SUCCESS'
export const EDIT_ADDRESS_ERROR = 'EDIT_ADDRESS_ERROR'
export const DEFAULT_ADDRESS_START = 'DEFAULT_ADDRESS_START'
export const DEFAULT_ADDRESS_SUCCESS = 'DEFAULT_ADDRESS_SUCCESS'
export const DEFAULT_ADDRESS_ERROR = 'DEFAULT_ADDRESS_ERROR'

/* CART */
export const ADD_ORDER_START = 'ADD_ORDER_START'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const ADD_ORDER_ERROR = 'ADD_ORDER_ERROR'
export const RESET_ADD_ORDER = 'RESET_ADD_ORDER'
export const ADD_SHIP_ORDER_START = 'ADD_SHIP_ORDER_START'
export const ADD_SHIP_ORDER_SUCCESS = 'ADD_SHIP_ORDER_SUCCESS'
export const ADD_SHIP_ORDER_ERROR = 'ADD_SHIP_ORDER_ERROR'
export const SAVE_TO_CART = 'SAVE_TO_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const CLEAR_TO_CART = 'CLEAR_TO_CART'

/* BUY ORDER - SHIP ORDER */
export const GET_BUY_ORDERS_START = 'GET_BUY_ORDERS_START'
export const GET_BUY_ORDERS_SUCCESS = 'GET_BUY_ORDERS_SUCCESS'
export const GET_BUY_ORDERS_ERROR = 'GET_BUY_ORDERS_ERROR'
export const GET_BUY_ORDER_DETAIL_START = 'GET_BUY_ORDER_DETAIL_START'
export const GET_BUY_ORDER_DETAIL_SUCCESS = 'GET_BUY_ORDER_DETAIL_SUCCESS'
export const GET_BUY_ORDER_DETAIL_ERROR = 'GET_BUY_ORDER_DETAIL_ERROR'
export const GET_SHIP_ORDERS_START = 'GET_SHIP_ORDERS_START'
export const GET_SHIP_ORDERS_SUCCESS = 'GET_SHIP_ORDERS_SUCCESS'
export const GET_SHIP_ORDERS_ERROR = 'GET_SHIP_ORDERS_ERROR'
export const GET_SHIP_ORDER_DETAIL_START = 'GET_SHIP_ORDER_DETAIL_START'
export const GET_SHIP_ORDER_DETAIL_SUCCESS = 'GET_SHIP_ORDER_DETAIL_SUCCESS'
export const GET_SHIP_ORDER_DETAIL_ERROR = 'GET_SHIP_ORDER_DETAIL_ERROR'

/* COMPLAINT */
export const GET_HAS_CHAT = 'GET_HAS_CHAT'

export const GET_COMPLAINT_START = 'GET_COMPLAINT_START'
export const GET_COMPLAINT_SUCCESS = 'GET_COMPLAINT_SUCCESS'
export const GET_COMPLAINT_ERROR = 'GET_COMPLAINT_ERROR'
export const GET_COMPLAINT_DETAIL_START = 'GET_COMPLAINT_DETAIL_START'
export const GET_COMPLAINT_DETAIL_SUCCESS = 'GET_COMPLAINT_DETAIL_SUCCESS'
export const GET_COMPLAINT_DETAIL_ERROR = 'GET_COMPLAINT_DETAIL_ERROR'
export const ADD_NEW_COMPLAINT_START = 'ADD_NEW_COMPLAINT_START'
export const ADD_NEW_COMPLAINT_SUCCESS = 'ADD_NEW_COMPLAINT_SUCCESS'
export const ADD_NEW_COMPLAINT_ERROR = 'ADD_NEW_COMPLAINT_ERROR'
export const REJECT_COMPLAINT_START = 'REJECT_COMPLAINT_START'
export const REJECT_COMPLAINT_SUCCESS = 'REJECT_COMPLAINT_SUCCESS'
export const ACCEPT_COMPLAINT_START = 'ACCEPT_COMPLAINT_START'
export const ACCEPT_COMPLAINT_SUCCESS = 'ACCEPT_COMPLAINT_SUCCESS'

/* CHAT APP */
export const ENABLE_IS_NEW_MESSAGE = 'ENABLE_IS_NEW_MESSAGE'
export const DISABLE_IS_NEW_MESSAGE = 'DISABLE_IS_NEW_MESSAGE'

export const READ_CHAT = 'READ_CHAT'

export const CHAT_GET_HISTORY = 'CHAT_GET_HISTORY'
export const CHAT_GET_HISTORY_SUCCESS = 'CHAT_GET_HISTORY_SUCCESS'
export const CHAT_GET_HISTORY_ERROR = 'CHAT_GET_HISTORY_ERROR'

export const CHAT_GET_CONVERSATION = 'CHAT_GET_CONVERSATION'
export const CHAT_GET_CONVERSATION_SUCCESS = 'CHAT_GET_CONVERSATION_SUCCESS'
export const CHAT_GET_CONVERSATION_ERROR = 'CHAT_GET_CONVERSATION_ERROR'

export const CHAT_ADD_MESSAGE_TO_CONVERSATION = 'CHAT_ADD_MESSAGE_TO_CONVERSATION'
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS = 'CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS'
export const CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR = 'CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR'

export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION'

export const CHAT_SEARCH_CONVERSATION = 'CHAT_SEARCH_CONVERSATION'

export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION'
export const CHAT_CHANGE_CONVERSATION_SUCCESS = 'CHAT_CHANGE_CONVERSATION_SUCCESS'
export const CHAT_CHANGE_CONVERSATION_ERROR = 'CHAT_CHANGE_CONVERSATION_ERROR'

export * from './menu/actions'
export * from './auth/actions'
export * from './province/actions'
export * from './district/actions'
export * from './branch/actions'
export * from './categories/actions'
export * from './address/actions'
export * from './cart/actions'
export * from './orders/actions'
export * from './complaint/actions'
export * from './chat/actions'
