import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import { selectContainerClassnames } from '../../redux/menu/selector'

import TopNav from './top-nav'
import Sidebar from './side-bar'
import { addToCart } from '../../redux/cart/actions'
import { selectAuthUserFeeData } from '../../redux/auth/selector'
import { Row } from 'reactstrap'
import { Colxx } from '../../components/custom-bootstrap'

const Layout = ({ history, children, containerClassnames, addToCart, customerFee }) => {
  useEffect(() => {
    let extensionCart = window.localStorage.getItem('extension-cart')
    if (extensionCart && customerFee) {
      extensionCart = JSON.parse(extensionCart)
      extensionCart = extensionCart.map((item) => {
        item.priceCNY = +item.priceCNY
        if (typeof item.priceVND === 'string') {
          item.priceVND = +item.priceVND.replace(/,/g, '')
        }
        item.totalCNY = item.priceCNY * item.quantity
        item.totalVND = item.priceVND * item.quantity

        return item
      })

      addToCart(extensionCart, customerFee)
      window.localStorage.removeItem('extension-cart')
    }

    // eslint-disable-next-line
  }, [JSON.stringify(customerFee)])

  return (
    <div id="app-container" className={containerClassnames}>
      <TopNav history={history} />
      <Sidebar />
      <main>
        <div className="container-fluid">{children}</div>
      </main>
      <footer className="page-footer">
        <div className="footer-content">
          <div className="container-fluid">
            <Row>
              <Colxx xxs="12" sm="6">
                <p className="mb-0 text-muted">
                  Bản quyền &copy; {new Date().getFullYear()} thuộc về ALIMUAHA. Bảo lưu mọi quyền.
                </p>
              </Colxx>
            </Row>
          </div>
        </div>
      </footer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  customerFee: selectAuthUserFeeData,
  containerClassnames: selectContainerClassnames,
})

const mapDispatchToProps = (dispatch) => ({
  addToCart: (items, customerFee) => dispatch(addToCart(items, customerFee)),
})

export const MainLayout = withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout))
