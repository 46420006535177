import {
  ACCEPT_COMPLAINT_START,
  ACCEPT_COMPLAINT_SUCCESS,
  ADD_NEW_COMPLAINT_ERROR,
  ADD_NEW_COMPLAINT_START,
  ADD_NEW_COMPLAINT_SUCCESS,
  GET_COMPLAINT_DETAIL_ERROR,
  GET_COMPLAINT_DETAIL_START,
  GET_COMPLAINT_DETAIL_SUCCESS,
  REJECT_COMPLAINT_START,
  REJECT_COMPLAINT_SUCCESS,
} from '../actions'

/**
 * Add New Complaint
 */
export const addNewComplaintStart = (complaint) => ({
  type: ADD_NEW_COMPLAINT_START,
  payload: { complaint },
})

export const addNewComplaintSuccess = (complaint) => ({
  type: ADD_NEW_COMPLAINT_SUCCESS,
  payload: { complaint },
})

export const addNewComplaintError = (error) => ({
  type: ADD_NEW_COMPLAINT_ERROR,
  payload: { error },
})

/**
 * Get Complaint Detail By Id
 */
export const getComplaintDetailStart = (id) => ({
  type: GET_COMPLAINT_DETAIL_START,
  payload: { id },
})

export const getComplaintDetailSuccess = (complaint) => ({
  type: GET_COMPLAINT_DETAIL_SUCCESS,
  payload: { complaint },
})

export const getComplaintDetailError = (error) => ({
  type: GET_COMPLAINT_DETAIL_ERROR,
  payload: { error },
})

export const rejectComplaintStart = (id) => ({
  type: REJECT_COMPLAINT_START,
  payload: { id },
})

export const rejectComplaintSuccess = (id) => ({
  type: REJECT_COMPLAINT_SUCCESS,
  payload: { id },
})

export const acceptComplaintStart = (id) => ({
  type: ACCEPT_COMPLAINT_START,
  payload: { id },
})

export const acceptComplaintSuccess = (id) => ({
  type: ACCEPT_COMPLAINT_SUCCESS,
  payload: { id },
})
