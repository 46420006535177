import { GET_BRANCH_ERROR, GET_BRANCH_START, GET_BRANCH_SUCCESS } from '../actions'

export const getBranchStart = () => ({
  type: GET_BRANCH_START,
})

export const getBranchSuccess = (branches) => ({
  type: GET_BRANCH_SUCCESS,
  payload: branches,
})

export const getBranchError = (error) => ({
  type: GET_BRANCH_ERROR,
  payload: error,
})
