import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  GET_BUY_ORDER_DETAIL_START,
  GET_BUY_ORDERS_START,
  GET_SHIP_ORDER_DETAIL_START,
  GET_SHIP_ORDERS_START,
} from '../actions'
import {
  getBuyOrderDetailError,
  getBuyOrderDetailSuccess,
  getBuyOrdersError,
  getBuyOrdersSuccess,
  getShipOrderDetailError,
  getShipOrderDetailSuccess,
  getShipOrdersError,
  getShipOrdersSuccess,
} from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'

/**
 * Get Buy Order
 */
export function* watchGetBuyOrders() {
  yield takeEvery(GET_BUY_ORDERS_START, getBuyOrders)
}

const getBuyOrdersAsync = () => {
  return apiClient.get('/buy-order')
}

function* getBuyOrders() {
  try {
    const response = yield call(getBuyOrdersAsync)
    yield put(getBuyOrdersSuccess(response.data.data))
  } catch (error) {
    notification.danger(error.message)
    yield put(getBuyOrdersError(error.message))
  }
}

/**
 * Get Buy Order Detail By Id
 */
export function* watchGetBuyOrderDetail() {
  yield takeEvery(GET_BUY_ORDER_DETAIL_START, getBuyOrderDetail)
}

const getBuyOrderDetailAsync = (id) => {
  return apiClient.get(`/buy-order/${id}`)
}

function* getBuyOrderDetail({ payload }) {
  const { id } = payload
  try {
    const response = yield call(getBuyOrderDetailAsync, id)
    if (!response.data.status) {
      yield put(getBuyOrderDetailError('Đã có lỗi xảy ra trong khi load chi tiết đơn hàng'))
      yield notification.danger('Đã có lỗi xảy ra trong khi load chi tiết đơn hàng')
    } else {
      yield put(getBuyOrderDetailSuccess(response.data.data))
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(getBuyOrderDetailError(error.message))
  }
}

/**
 * Get Ship Order
 */
export function* watchGetShipOrders() {
  yield takeEvery(GET_SHIP_ORDERS_START, getShipOrders)
}

const getShipOrdersAsync = () => {
  return apiClient.get('/ship-order')
}

function* getShipOrders() {
  try {
    const response = yield call(getShipOrdersAsync)
    yield put(getShipOrdersSuccess(response.data.data))
  } catch (error) {
    notification.danger(error.message)
    yield put(getShipOrdersError(error.message))
  }
}

/**
 * Get Ship Order Detail By Id
 */
export function* watchGetShipOrderDetail() {
  yield takeEvery(GET_SHIP_ORDER_DETAIL_START, getShipOrderDetail)
}

const getShipOrderDetailAsync = (id) => {
  return apiClient.get(`/ship-order/${id}`)
}

function* getShipOrderDetail({ payload }) {
  const { id } = payload
  try {
    const response = yield call(getShipOrderDetailAsync, id)
    if (!response.data.status) {
      yield put(getShipOrderDetailError('Đã có lỗi xảy ra trong khi load chi tiết đơn hàng ký gửi'))
      yield notification.danger('Đã có lỗi xảy ra trong khi load chi tiết đơn hàng ký gửi')
    } else {
      yield put(getShipOrderDetailSuccess(response.data.data))
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(getShipOrderDetailError(error.message))
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBuyOrders),
    fork(watchGetShipOrders),
    fork(watchGetBuyOrderDetail),
    fork(watchGetShipOrderDetail),
  ])
}
