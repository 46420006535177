import {
  GET_BUY_ORDER_DETAIL_ERROR,
  GET_BUY_ORDER_DETAIL_START,
  GET_BUY_ORDER_DETAIL_SUCCESS,
  GET_BUY_ORDERS_ERROR,
  GET_BUY_ORDERS_START,
  GET_BUY_ORDERS_SUCCESS,
  GET_SHIP_ORDER_DETAIL_ERROR,
  GET_SHIP_ORDER_DETAIL_START,
  GET_SHIP_ORDER_DETAIL_SUCCESS,
  GET_SHIP_ORDERS_ERROR,
  GET_SHIP_ORDERS_START,
  GET_SHIP_ORDERS_SUCCESS,
} from '../actions'

const INIT_STATE = {
  getBuyOrders: {
    isLoading: false,
    items: null,
    error: '',
  },
  getBuyOrderDetail: {
    isLoading: false,
    data: null,
    error: '',
  },
  getShipOrders: {
    isLoading: false,
    items: null,
    error: '',
  },
  getShipOrderDetail: {
    isLoading: false,
    data: null,
    error: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUY_ORDERS_START:
      return {
        ...state,
        getBuyOrders: {
          isLoading: true,
          items: null,
          error: '',
        },
      }

    case GET_BUY_ORDERS_SUCCESS:
      return {
        ...state,
        getBuyOrders: {
          isLoading: false,
          items: action.payload.buyOrders,
          error: '',
        },
      }

    case GET_BUY_ORDERS_ERROR:
      return {
        ...state,
        getBuyOrders: {
          isLoading: false,
          items: null,
          error: action.payload.error,
        },
      }

    case GET_BUY_ORDER_DETAIL_START:
      return {
        ...state,
        getBuyOrderDetail: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case GET_BUY_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        getBuyOrderDetail: {
          isLoading: false,
          data: action.payload.buyOrder,
          error: '',
        },
      }

    case GET_BUY_ORDER_DETAIL_ERROR:
      return {
        ...state,
        getBuyOrderDetail: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    case GET_SHIP_ORDERS_START:
      return {
        ...state,
        getShipOrders: {
          isLoading: true,
          items: null,
          error: '',
        },
      }

    case GET_SHIP_ORDERS_SUCCESS:
      return {
        ...state,
        getShipOrders: {
          isLoading: false,
          items: action.payload.shipOrders,
          error: '',
        },
      }

    case GET_SHIP_ORDERS_ERROR:
      return {
        ...state,
        getShipOrders: {
          isLoading: false,
          items: null,
          error: action.payload.error,
        },
      }

    case GET_SHIP_ORDER_DETAIL_START:
      return {
        ...state,
        getShipOrderDetail: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case GET_SHIP_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        getShipOrderDetail: {
          isLoading: false,
          data: action.payload.shipOrder,
          error: '',
        },
      }

    case GET_SHIP_ORDER_DETAIL_ERROR:
      return {
        ...state,
        getShipOrderDetail: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    default:
      return state
  }
}
