import { GET_DISTRICT_ERROR, GET_DISTRICT_START, GET_DISTRICT_SUCCESS } from '../actions'

export const getDistrictStart = (id) => ({
  type: GET_DISTRICT_START,
  payload: { id },
})

export const getDistrictSuccess = (districts) => ({
  type: GET_DISTRICT_SUCCESS,
  payload: districts,
})

export const getDistrictError = (error) => ({
  type: GET_DISTRICT_ERROR,
  payload: error,
})
