import { createSelector } from 'reselect'
import { isEmpty } from 'lodash'

const selectAuthUser = (state) => state.authUser
const selectAuthUserProfile = (state) => state.authUser.profile
const selectAuthUserFee = (state) => state.authUser.userFee
const selectAuthUserChangePassword = (state) => state.authUser.changePassword

export const selectAuthUserIsLoading = createSelector([selectAuthUser], (authUser) => authUser.loading)

export const selectAuthUserMessage = createSelector([selectAuthUser], (authUser) => authUser.message)

export const selectAuthUserError = createSelector([selectAuthUser], (authUser) => authUser.error)

const profileMapped = (profile) => {
  if (!isEmpty(profile)) {
    return {
      ...profile,
      branch: {
        value: profile.branch._id,
        label: profile.branch.name,
      },
    }
  }
}

export const selectAuthUserProfileMapped = createSelector([selectAuthUserProfile], (profile) =>
  profile ? profileMapped(profile) : null
)

export const selectAuthUserFeeData = createSelector([selectAuthUserFee], (userFee) => (userFee ? userFee.data : null))

export const selectAuthUserChangePasswordIsLoading = createSelector(
  [selectAuthUserChangePassword],
  (changePassword) => changePassword.isLoading
)
