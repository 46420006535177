import { createSelector } from 'reselect'

const selectChatApp = (state) => state.chatApp
const selectChatHistory = (state) => state.chatApp.chatHistory

export const selectLoadingChatHistory = createSelector([selectChatHistory], (chatHistory) => chatHistory.isLoading)

export const selectDataChatHistory = createSelector([selectChatHistory], (chatHistory) =>
  chatHistory.data ? chatHistory.data : []
)

export const selectLoadingConversation = createSelector([selectChatApp], (chatApp) => chatApp.loadingConversation)

export const selectSelectedChatId = createSelector([selectChatApp], (chatApp) => chatApp.selectedChatId)

export const selectIsNewMessage = createSelector([selectChatApp], (chatApp) => chatApp.isNewMessage)

export const selectUnreadChatNumber = createSelector([selectChatApp], (chatHistory) => {
  let chatList = chatHistory.data ? chatHistory.data : []
  console.log(chatList)
  let unreadChatList = chatList.filter((chat) => chat.readStatus !== true)

  return unreadChatList.length
})
