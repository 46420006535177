import './assets/styles/bootstrap.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-table/react-table.css'
import 'animate.css/animate.css'
import './assets/styles/gogo.light.blue.scss'
import 'react-notifications-component/dist/theme.css'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import ReactNotification from 'react-notifications-component'
import { App } from './app'
import { Loading } from './components/loading'

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Suspense fallback={<Loading />}>
        <ReactNotification />
        <App />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
