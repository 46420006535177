import { GET_PROVINCE_ERROR, GET_PROVINCE_START, GET_PROVINCE_SUCCESS } from '../actions'

export const getProvinceStart = () => ({
  type: GET_PROVINCE_START,
})

export const getProvinceSuccess = (provinces) => ({
  type: GET_PROVINCE_SUCCESS,
  payload: provinces,
})

export const getProvinceError = (error) => ({
  type: GET_PROVINCE_ERROR,
  payload: error,
})
