import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ADD_ORDER_START, ADD_SHIP_ORDER_START } from '../actions'
import { addOrderError, addOrderSuccess, addShipOrderError, addShipOrderSuccess } from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'

/**
 * Add Buy Order
 */
export function* watchAddOrder() {
  yield takeEvery(ADD_ORDER_START, addOrder)
}

const addOrderAsync = (order) => {
  return apiClient.post('/buy-order/new', order)
}

function* addOrder({ payload }) {
  const { order } = payload
  try {
    const response = yield call(addOrderAsync, order)
    if (response.data.status) {
      yield put(addOrderSuccess('Đơn hàng đã được đặt thành công.'))
      notification.success('Đơn hàng đã được đặt thành công.')
    } else {
      yield put(addOrderError('Không thành công.'))
      notification.danger('Không thành công.')
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(addOrderError(error.message))
  }
}

/**
 * Add Ship Order
 */
export function* watchAddShipOrder() {
  yield takeEvery(ADD_SHIP_ORDER_START, addShipOrder)
}

const addShipOrderAsync = (shipOrder) => {
  return apiClient.post('/ship-order/new', shipOrder)
}

function* addShipOrder({ payload }) {
  const { shipOrder } = payload
  try {
    const response = yield call(addShipOrderAsync, shipOrder)
    if (response.data.status) {
      yield put(addShipOrderSuccess('Đơn ký gửi đã được đặt thành công.'))
      notification.success('Đơn ký gửi đã được đặt thành công.')
    } else {
      yield put(addShipOrderError('Không thành công.'))
      notification.danger('Không thành công.')
    }
  } catch (error) {
    notification.danger(error.message)
    yield put(addShipOrderError(error.message))
  }
}

export default function* rootSaga() {
  yield all([fork(watchAddOrder), fork(watchAddShipOrder)])
}
