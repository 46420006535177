export const addMessageToChat = (chatHistory, messageCreated) => {
  const existingChat = chatHistory.find((chatItem) => chatItem._id === messageCreated.chat)

  if (existingChat) {
    return chatHistory.map((chatItem) =>
      chatItem._id === messageCreated.chat
        ? {
            ...chatItem,
            messages: [...chatItem.messages, messageCreated.message],
          }
        : chatItem
    )
  } else {
    return chatHistory.unshift({
      _id: messageCreated.chat,
      messages: [messageCreated.message],
      createdAt: messageCreated.createdAt,
    })
  }
}
