import {
  ADD_ADDRESS_ERROR,
  ADD_ADDRESS_START,
  ADD_ADDRESS_SUCCESS,
  DEFAULT_ADDRESS_ERROR,
  DEFAULT_ADDRESS_START,
  DEFAULT_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  DELETE_ADDRESS_START,
  DELETE_ADDRESS_SUCCESS,
  EDIT_ADDRESS_ERROR,
  EDIT_ADDRESS_START,
  EDIT_ADDRESS_SUCCESS,
  GET_ADDRESS_ERROR,
  GET_ADDRESS_START,
  GET_ADDRESS_SUCCESS,
} from '../actions'

const INIT_STATE = {
  isLoading: false,
  addressItems: null,
  error: '',
  addNewAddress: {
    isLoading: false,
    message: '',
    error: '',
  },
  editAddress: {
    isLoading: false,
    message: '',
    error: '',
  },
  defaultAddress: {
    isLoading: false,
    message: '',
    error: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADDRESS_START:
      return { ...state, isLoading: true }

    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addressItems: action.payload.address,
      }

    case GET_ADDRESS_ERROR:
      return { ...state, isLoading: false, error: action.payload.error }

    case DELETE_ADDRESS_START:
      return {
        ...state,
        isLoading: true,
        error: '',
      }

    case DELETE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addressItems: state.addressItems.filter((address) => address._id !== action.payload.id),
        error: '',
      }

    case DELETE_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      }

    case ADD_ADDRESS_START:
      return {
        ...state,
        addNewAddress: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addNewAddress: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case ADD_ADDRESS_ERROR:
      return {
        ...state,
        addNewAddress: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    case EDIT_ADDRESS_START:
      return {
        ...state,
        editAddress: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        editAddress: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case EDIT_ADDRESS_ERROR:
      return {
        ...state,
        editAddress: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    case DEFAULT_ADDRESS_START:
      return {
        ...state,
        defaultAddress: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        defaultAddress: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case DEFAULT_ADDRESS_ERROR:
      return {
        ...state,
        defaultAddress: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    default:
      return state
  }
}
