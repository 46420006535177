import { GET_DISTRICT_ERROR, GET_DISTRICT_START, GET_DISTRICT_SUCCESS } from '../actions'

const INIT_STATE = {
  isLoading: false,
  districtItems: null,
  error: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISTRICT_START:
      return { ...state, isLoading: true }

    case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        districtItems: action.payload,
      }

    case GET_DISTRICT_ERROR:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return { ...state }
  }
}
