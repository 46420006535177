import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects'
import {
  GET_PROFILE_START,
  GET_USER_FEE_START,
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  UPDATE_PASSWORD_START,
  UPDATE_PROFILE_START,
} from '../actions'
import { authenticate, signout } from '../../helpers/auth-helper'
import notification from '../../helpers/notification'
import { apiClient } from '../../helpers/axios'

import {
  getProfileError,
  getProfileStart,
  getProfileSuccess,
  getUserFeeError,
  getUserFeeSuccess,
  loginUserError,
  loginUserSuccess,
  registerUserError,
  registerUserSuccess,
  updatePasswordError,
  updatePasswordSuccess,
  updateProfileError,
  updateProfileSuccess,
} from './actions'
import { clearToCart } from '../cart/actions'

/**
 * LOGIN USER
 */
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginUser)
}

const loginUserAsync = (email, password) => {
  return apiClient.post('/customer/login', {
    email,
    password,
  })
}

function* loginUser({ payload }) {
  const { email, password } = payload.user
  const { history, from } = payload
  try {
    const response = yield call(loginUserAsync, email, password)
    if (!response.data.status) {
      yield put(loginUserError(response.data.message))
      yield notification.danger(response.data.message)
    } else {
      yield authenticate(response.data.data.accessToken)
      yield put(loginUserSuccess(response.data.data))
      history.push(from) // app
    }
  } catch (error) {
    yield put(loginUserError(error))
  }
}

/**
 * REGISTER USER
 */
export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerUser)
}

const registerUserAsync = (user) => {
  return apiClient.post('/customer/register', user)
}

function* registerUser({ payload }) {
  const { user, history } = payload
  try {
    const response = yield call(registerUserAsync, user)
    if (!response.data.status) {
      yield put(registerUserError(response.data.message))
      yield notification.danger(response.data.message)
    } else {
      yield put(registerUserSuccess(response.data.message))
      yield notification.success(response.data.message)
      history.push('/login')
    }
  } catch (error) {
    yield put(registerUserError(error))
  }
}

/**
 * GET PROFILE
 */
export function* watchGetProfile() {
  yield takeEvery(GET_PROFILE_START, getProfile)
}

const getProfileAsync = () => {
  return apiClient.get('/customer/profile')
}

function* getProfile() {
  try {
    const response = yield call(getProfileAsync)
    if (response.data.status) {
      yield put(getProfileSuccess(response.data.data))
    }
  } catch (error) {
    yield put(getProfileError(error))
  }
}

/**
 * UPDATE PROFILE
 */
export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_PROFILE_START, updateProfile)
}

const updateProfileAsync = (profile) => {
  return apiClient.post('/customer/profile', profile)
}

function* updateProfile({ payload }) {
  const { profile } = payload
  try {
    const response = yield call(updateProfileAsync, profile)
    yield put(updateProfileSuccess(response.data.message))
    // Re-Update profile
    yield put(getProfileStart())
    yield notification.success(response.data.message)
  } catch (error) {
    yield put(updateProfileError(error))
  }
}

/**
 * CHANGE PASSWORD
 */
export function* watchChangePassword() {
  yield takeEvery(UPDATE_PASSWORD_START, changePassword)
}

const changePasswordAsync = (passwordData) => {
  return apiClient.post('/customer/password', passwordData)
}

function* changePassword({ payload }) {
  const { passwordData, history } = payload
  try {
    const response = yield call(changePasswordAsync, passwordData)
    if (!response.data.status) {
      yield put(updatePasswordError(response.data.message))
      notification.danger(response.data.message)
    } else {
      yield put(updatePasswordSuccess(response.data.message))
      notification.success('Vui lòng đăng nhập lại sau 5 giây', response.data.message)
      // Required logout after change password
      yield delay(5000)
      yield signout()
      history.push('/')
    }
  } catch (error) {
    yield put(updatePasswordError(error.message))
    yield notification.danger(error.message)
  }
}

/**
 * Get Customer Fee
 */
export function* watchGetCustomerFee() {
  yield takeEvery(GET_USER_FEE_START, getCustomerFee)
}

const getCustomerFeeAsync = () => {
  return apiClient.get('/customer/fee')
}

function* getCustomerFee() {
  try {
    const response = yield call(getCustomerFeeAsync)
    yield put(getUserFeeSuccess(response.data.data))
  } catch (error) {
    yield put(getUserFeeError(error.message))
  }
}

/**
 * LOGOUT
 */
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout)
}

function* logout({ payload }) {
  const { history } = payload

  try {
    yield put(clearToCart())
    yield signout()
    yield history.push('/login')
    window.location.reload()
  } catch (error) {}
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchGetProfile),
    fork(watchUpdateProfile),
    fork(watchChangePassword),
    fork(watchGetCustomerFee),
  ])
}
