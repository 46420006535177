import { GET_BRANCH_ERROR, GET_BRANCH_START, GET_BRANCH_SUCCESS } from '../actions'

const INIT_STATE = {
  isLoading: false,
  branchItems: null,
  error: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCH_START:
      return { ...state, isLoading: true }

    case GET_BRANCH_SUCCESS:
      return { ...state, isLoading: false, branchItems: action.payload }

    case GET_BRANCH_ERROR:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return { ...state }
  }
}
