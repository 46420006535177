export const menuItems = [
  {
    id: 'dashboard',
    icon: 'iconsminds-folder',
    label: 'TRANG CHỦ',
    to: '/dashboards',
  },
  {
    id: 'create-buy-order',
    icon: 'simple-icon-doc',
    label: 'TẠO ĐƠN',
    to: '/buy-order-new',
  },
  {
    id: 'create-ship-order',
    icon: 'simple-icon-note',
    label: 'TẠO ĐƠN KÍ GỬI',
    to: '/buy-ship-new',
  },
  {
    id: 'manage-order',
    icon: 'simple-icon-notebook',
    label: 'QUẢN LÝ ĐƠN HÀNG',
    to: '/buy-order-list',
  },
  {
    id: 'manage-ship-order',
    icon: 'simple-icon-screen-tablet',
    label: 'QUẢN LÝ ĐƠN KÝ GỬI',
    to: '/ship-order-list',
  },
  {
    id: 'e-wallet',
    icon: 'simple-icon-wallet',
    label: 'VÍ ĐIỆN TỬ',
    to: '/e-wallet',
  },
  {
    id: 'complaint',
    icon: 'simple-icon-speech',
    label: 'KHIẾU NẠI',
    to: '/complaint',
  },
]
