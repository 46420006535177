import {
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS,
  CHAT_GET_CONVERSATION,
  CHAT_GET_CONVERSATION_ERROR,
  CHAT_GET_CONVERSATION_SUCCESS,
  CHAT_GET_HISTORY,
  CHAT_GET_HISTORY_ERROR,
  CHAT_GET_HISTORY_SUCCESS,
  DISABLE_IS_NEW_MESSAGE,
  ENABLE_IS_NEW_MESSAGE,
  GET_HAS_CHAT,
  READ_CHAT,
} from '../actions'

export const getHasChat = () => ({
  type: GET_HAS_CHAT,
})

export const enableIsNewMessage = () => ({
  type: ENABLE_IS_NEW_MESSAGE,
})
export const disableIsNewMessage = () => ({
  type: DISABLE_IS_NEW_MESSAGE,
})

export const getChatHistory = () => ({
  type: CHAT_GET_HISTORY,
})
export const getChatHistorySuccess = (chatHistory, selectedChatId) => ({
  type: CHAT_GET_HISTORY_SUCCESS,
  payload: { chatHistory, selectedChatId },
})
export const getChatHistoryError = (error) => ({
  type: CHAT_GET_HISTORY_ERROR,
  payload: { error },
})

export const readChatStatus = (chatId) => ({
  type: READ_CHAT,
  payload: { chatId },
})

export const getConversation = (chatId) => ({
  type: CHAT_GET_CONVERSATION,
  payload: { chatId },
})
export const getConversationSuccess = (conversation, selectedChatId) => ({
  type: CHAT_GET_CONVERSATION_SUCCESS,
  payload: { conversation, selectedChatId },
})
export const getConversationError = (error) => ({
  type: CHAT_GET_CONVERSATION_ERROR,
  payload: { error },
})

export const addMessageToConversation = (msg) => ({
  type: CHAT_ADD_MESSAGE_TO_CONVERSATION,
  payload: { msg },
})
export const addMessageToConversationSuccess = (message) => ({
  type: CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS,
  payload: { message },
})
export const addMessageToConversationError = (error) => ({
  type: CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR,
  payload: { error },
})
