import {
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS,
  CHAT_GET_CONVERSATION,
  CHAT_GET_CONVERSATION_ERROR,
  CHAT_GET_CONVERSATION_SUCCESS,
  CHAT_GET_HISTORY,
  CHAT_GET_HISTORY_ERROR,
  CHAT_GET_HISTORY_SUCCESS,
  DISABLE_IS_NEW_MESSAGE,
  ENABLE_IS_NEW_MESSAGE,
  READ_CHAT,
} from '../actions'
import { addMessageToChat } from './util'

const INIT_STATE = {
  isNewMessage: false,
  chatHistory: {
    isLoading: false,
    data: null,
    error: '',
  },
  loadingConversation: false,
  loadingAddMessage: false,
  selectedChatId: '',
  searchKeyword: '',
  error: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENABLE_IS_NEW_MESSAGE:
      return {
        ...state,
        isNewMessage: true,
      }

    case DISABLE_IS_NEW_MESSAGE:
      return {
        ...state,
        isNewMessage: false,
      }

    case CHAT_GET_HISTORY:
      return {
        ...state,
        chatHistory: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case CHAT_GET_HISTORY_SUCCESS:
      return {
        ...state,
        chatHistory: {
          isLoading: false,
          data: action.payload.chatHistory,
          error: '',
        },
        selectedChatId: action.payload.selectedChatId,
      }

    case CHAT_GET_HISTORY_ERROR:
      return {
        ...state,
        chatHistory: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    case CHAT_GET_CONVERSATION:
      return {
        ...state,
        loadingConversation: true,
      }

    case CHAT_GET_CONVERSATION_SUCCESS:
      return {
        ...state,
        loadingConversation: false,
        chatHistory: {
          data: state.chatHistory.data.map((item) => {
            if (item._id === action.payload.conversation._id) {
              item.messages = action.payload.conversation.messages
            }
            return item
          }),
        },
        selectedChatId: action.payload.selectedChatId,
        error: '',
      }

    case CHAT_GET_CONVERSATION_ERROR:
      return {
        ...state,
        loadingConversation: false,
        error: action.payload.error,
      }

    case CHAT_ADD_MESSAGE_TO_CONVERSATION:
      return {
        ...state,
        loadingAddMessage: true,
      }

    case CHAT_ADD_MESSAGE_TO_CONVERSATION_SUCCESS:
      return {
        ...state,
        loadingAddMessage: false,
        chatHistory: {
          data: addMessageToChat(state.chatHistory.data, action.payload.message),
        },
        error: '',
      }

    case CHAT_ADD_MESSAGE_TO_CONVERSATION_ERROR:
      return {
        ...state,
        loadingAddMessage: false,
        error: action.payload.error,
      }

    case READ_CHAT:
      return {
        ...state,
        chatHistory: {
          data: state.chatHistory.data.map((item) => {
            if (item._id === action.payload.chatId) {
              item.readStatus = true
            }
            return item
          }),
        },
      }

    default:
      return state
  }
}
