import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import sagas from './sagas'
import reducers from './reducers'

// Redux persist
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

// Persist config
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart'],
  stateReconciler: autoMergeLevel2,
}
const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const middlewares = [sagaMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)
const enhancers = [middlewareEnhancer]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(...enhancers)

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, initialState, composedEnhancers)

sagaMiddleware.run(sagas)

if (module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers')
    store.replaceReducer(nextRootReducer)
  })
}

export default store
export const persistor = persistStore(store)
