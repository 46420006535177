import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  ADD_ADDRESS_START,
  DEFAULT_ADDRESS_START,
  DELETE_ADDRESS_START,
  EDIT_ADDRESS_START,
  GET_ADDRESS_START,
} from '../actions'
import {
  addAddressError,
  addAddressSuccess,
  defaultAddressError,
  defaultAddressSuccess,
  deleteAddressError,
  deleteAddressSuccess,
  editAddressError,
  editAddressSuccess,
  getAddressError,
  getAddressStart,
  getAddressSuccess,
} from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'

export function* watchGetAddress() {
  yield takeEvery(GET_ADDRESS_START, getAddress)
}

const getAddressAsync = () => {
  return apiClient.get('/address')
}

function* getAddress() {
  try {
    const response = yield call(getAddressAsync)
    yield put(getAddressSuccess(response.data.data))
  } catch (error) {
    notification.danger(error.message)
    yield put(getAddressError(error.message))
  }
}

export function* watchDeleteAddress() {
  yield takeLatest(DELETE_ADDRESS_START, deleteAddress)
}

const deleteAddressAsync = (id) => {
  return apiClient.post(`/address/${id}/delete`, null)
}

function* deleteAddress({ payload }) {
  const { id } = payload
  try {
    yield call(deleteAddressAsync, id)
    yield put(deleteAddressSuccess(id))
    notification.success('Địa chỉ đã được xóa.', 'Thành công!')
  } catch (error) {
    notification.danger(error.message)
    yield put(deleteAddressError(error.message))
  }
}

export function* watchAddAddress() {
  yield takeEvery(ADD_ADDRESS_START, addAddress)
}

const addAddressAsync = (address) => {
  return apiClient.post('/address/new', address)
}

function* addAddress({ payload }) {
  const { address } = payload
  try {
    yield call(addAddressAsync, address)
    yield put(addAddressSuccess('Đã thêm địa chỉ nhận hàng'))
    // Re-Update List Address To Get New State
    yield put(getAddressStart())
    notification.success('Đã thêm địa chỉ nhận hàng', 'Thành công!')
  } catch (error) {
    notification.danger(error.message)
    yield put(addAddressError(error.message))
  }
}

export function* watchEditAddress() {
  yield takeEvery(EDIT_ADDRESS_START, editAddress)
}

const editAddressAsync = (id, address) => {
  return apiClient.post(`/address/${id}`, address)
}

function* editAddress({ payload }) {
  const { address } = payload
  const { _id, ...restAddress } = address
  try {
    yield call(editAddressAsync, _id, restAddress)
    yield put(editAddressSuccess('Đã cập nhật địa chỉ nhận hàng'))
    // Re-Update List Address To Get New State
    yield put(getAddressStart())
    notification.success('Đã cập nhật địa chỉ nhận hàng', 'Thành công!')
  } catch (error) {
    notification.danger(error.message)
    yield put(editAddressError(error.message))
  }
}

export function* watchDefaultAddress() {
  yield takeLatest(DEFAULT_ADDRESS_START, defaultAddress)
}

const defaultAddressAsync = (id) => {
  return apiClient.post(`/address/${id}/default`, null)
}

function* defaultAddress({ payload }) {
  const { id } = payload
  try {
    yield call(defaultAddressAsync, id)
    yield put(defaultAddressSuccess('Đã thiết lập địa chỉ nhận hàng mặc định.'))
    // Re-Update List Address To Get New State
    yield put(getAddressStart())
    notification.success('Đã thiết lập địa chỉ nhận hàng mặc định.', 'Thành công!')
  } catch (error) {
    notification.danger(error.message)
    yield put(defaultAddressError(error.message))
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAddress),
    fork(watchDeleteAddress),
    fork(watchAddAddress),
    fork(watchEditAddress),
    fork(watchDefaultAddress),
  ])
}
