import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { GET_DISTRICT_START } from '../actions'
import { getDistrictError, getDistrictSuccess } from './actions'
import axios from 'axios'

export function* watchGetDistrict() {
  yield takeEvery(GET_DISTRICT_START, getDistrict)
}

const getDistrictAsync = (id) => {
  return axios.get(`${process.env.REACT_APP_API}/api/province/${id}`)
}

function* getDistrict({ payload }) {
  const { id } = payload
  try {
    const response = yield call(getDistrictAsync, id)
    yield put(getDistrictSuccess(response.data.data))
  } catch (error) {
    yield put(getDistrictError(error))
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetDistrict)])
}
