import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { GET_PROVINCE_START } from '../actions'
import { getProvinceError, getProvinceSuccess } from './actions'
import axios from 'axios'

export function* watchGetProvince() {
  yield takeEvery(GET_PROVINCE_START, getProvince)
}

const getProvinceAsync = () => {
  return axios.get(`${process.env.REACT_APP_API}/api/province/all`)
}

function* getProvince() {
  try {
    const response = yield call(getProvinceAsync)
    yield put(getProvinceSuccess(response.data.data))
  } catch (error) {
    yield put(getProvinceError(error))
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetProvince)])
}
