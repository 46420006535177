export const formatCurrency = (money, toFixed = 0) =>
  (isNaN(money) ? 0 : +money).toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

export const calculateFee = (value, feeInfo) => {
  let totalFee = 0
  if (value < 2000000) {
    totalFee = (value * feeInfo.below2m) / 100
  } else if (value >= 2000000 && value <= 20000000) {
    totalFee = (value * feeInfo.from2mTo20m) / 100
  } else if (value >= 20000000 && value <= 10000000) {
    totalFee = (value * feeInfo.from20mTo100m) / 100
  } else if (value > 100000000) {
    totalFee = (value * feeInfo.above100m) / 100
  }

  return totalFee
}

export const buildUrl = (url) => {
  return url.indexOf('http') === -1 ? process.env.REACT_APP_API + url : url
}

export const isMobile = () => {
  const isMobileUserAgent = !!(
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )

  const isTouchSupport = window.hasOwnProperty('ontouchstart')

  const isMobileDeviceWidth = window.innerWidth < 576

  return isMobileUserAgent && isTouchSupport && isMobileDeviceWidth
}
