import { all } from 'redux-saga/effects'
import authSagas from './auth/saga'
import provinceSagas from './province/saga'
import districtSagas from './district/saga'
import branchSagas from './branch/saga'
import categorySagas from './categories/saga'
import addressSagas from './address/saga'
import cartSagas from './cart/saga'
import ordersSagas from './orders/saga'
import complaintSagas from './complaint/saga'
import chatSagas from './chat/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    provinceSagas(),
    districtSagas(),
    branchSagas(),
    categorySagas(),
    addressSagas(),
    cartSagas(),
    ordersSagas(),
    complaintSagas(),
    chatSagas(),
  ])
}
