import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { GET_BRANCH_START } from '../actions'
import { getBranchError, getBranchSuccess } from './actions'
import axios from 'axios'

export function* watchGetBranch() {
  yield takeEvery(GET_BRANCH_START, getBranch)
}

const getBranchAsync = () => {
  return axios.get(`${process.env.REACT_APP_API}/api/branch/all`)
}

function* getBranch() {
  try {
    const response = yield call(getBranchAsync)
    yield put(getBranchSuccess(response.data.data))
  } catch (error) {
    yield put(getBranchError(error))
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetBranch)])
}
