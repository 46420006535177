import React from 'react'
import PropTypes from 'prop-types'
import NotificationManager from './notification-manager'
import NotificationList from './notification-list'

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props)
    NotificationManager.addChangeListener(this.handleStoreChange)
  }

  static propTypes = {
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number,
  }

  static defaultProps = {
    enterTimeout: 400,
    leaveTimeout: 400,
  }

  state = {
    notifications: [],
  }

  componentWillUnmount = () => {
    NotificationManager.removeChangeListener(this.handleStoreChange)
  }

  handleStoreChange = (notifications) => {
    this.setState({
      notifications,
    })
  }

  handleRequestHide = (notification) => {
    NotificationManager.remove(notification)
  }

  render() {
    const { notifications } = this.state
    const { enterTimeout, leaveTimeout } = this.props
    return (
      <NotificationList
        enterTimeout={enterTimeout}
        leaveTimeout={leaveTimeout}
        notifications={notifications}
        onRequestHide={this.handleRequestHide}
      />
    )
  }
}

export default NotificationContainer
