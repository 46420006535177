import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  GET_PROFILE_ERROR,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,
  GET_USER_FEE_ERROR,
  GET_USER_FEE_START,
  GET_USER_FEE_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_ERROR,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
} from '../actions'

const INIT_STATE = {
  user: null,
  profile: null,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  message: '',
  error: '',
  changePassword: {
    isLoading: false,
    message: '',
    error: '',
  },
  userFee: {
    isLoading: false,
    data: null,
    error: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' }

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.email,
        error: '',
      }

    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
      }

    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' }

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      }

    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      }

    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' }

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        newPassword: action.payload,
        resetPasswordCode: '',
        error: '',
      }

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        newPassword: '',
        resetPasswordCode: '',
        error: action.payload.message,
      }

    case REGISTER_USER:
      return { ...state, loading: true, error: '' }

    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: '',
      }

    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload.message,
      }

    case GET_PROFILE_START:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
        error: '',
      }

    case GET_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        profile: null,
        error: action.payload.message,
      }

    case UPDATE_PROFILE_START:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: '',
      }

    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        message: '',
        error: action.payload.message,
      }

    case UPDATE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          isLoading: true,
          message: '',
          error: '',
        },
      }

    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          isLoading: false,
          message: action.payload.message,
          error: '',
        },
      }

    case UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: {
          isLoading: false,
          message: '',
          error: action.payload.error,
        },
      }

    case GET_USER_FEE_START:
      return {
        ...state,
        userFee: {
          isLoading: true,
          data: null,
          error: '',
        },
      }

    case GET_USER_FEE_SUCCESS:
      return {
        ...state,
        userFee: {
          isLoading: false,
          data: action.payload.fees,
          error: '',
        },
      }

    case GET_USER_FEE_ERROR:
      return {
        ...state,
        userFee: {
          isLoading: false,
          data: null,
          error: action.payload.error,
        },
      }

    case LOGOUT_USER:
      return { ...INIT_STATE }

    default:
      return state
  }
}
