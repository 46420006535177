import { GET_CATEGORIES_ERROR, GET_CATEGORIES_START, GET_CATEGORIES_SUCCESS } from '../actions'

const INIT_STATE = {
  isLoading: false,
  categoryItems: null,
  error: '',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_START:
      return { ...state, isLoading: true }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categoryItems: action.payload.categories,
      }

    case GET_CATEGORIES_ERROR:
      return { ...state, isLoading: false, error: action.payload.error }

    default:
      return state
  }
}
