import { store } from 'react-notifications-component'

export default {
  success: (message, title = 'Chúc Mừng!') => {
    store.addNotification({
      title: title,
      message: message,
      type: 'success',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__bounceInDown'],
      animationOut: ['animate__animated', 'animate__bounceOutUp'],
      dismiss: {
        duration: 5000,
        onScreen: false,
        pauseOnHover: true,
      },
    })
  },
  danger: (message, title = 'Xin Lỗi!') => {
    store.addNotification({
      title: title,
      message: message,
      type: 'danger',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__bounceInDown'],
      animationOut: ['animate__animated', 'animate__bounceOutUp'],
      dismiss: {
        duration: 5000,
        onScreen: false,
        pauseOnHover: true,
      },
    })
  },
  info: (message, title = '') => {
    store.addNotification({
      title: title,
      message: message,
      type: 'info',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__bounceInDown'],
      animationOut: ['animate__animated', 'animate__bounceOutUp'],
      dismiss: {
        duration: 5000,
        onScreen: false,
        pauseOnHover: true,
      },
    })
  },
  warning: (message, title = '') => {
    store.addNotification({
      title: title,
      message: message,
      type: 'warning',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__bounceInDown'],
      animationOut: ['animate__animated', 'animate__bounceOutUp'],
      dismiss: {
        duration: 5000,
        onScreen: false,
        pauseOnHover: true,
      },
    })
  },
}
