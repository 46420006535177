import { combineReducers } from 'redux'
import menu from './menu/reducer'
import authUser from './auth/reducer'
import provinces from './province/reducer'
import districts from './district/reducer'
import branches from './branch/reducer'
import categories from './categories/reducer'
import address from './address/reducer'
import cart from './cart/reducer'
import orders from './orders/reducer'
import complaint from './complaint/reducer'
import chatApp from './chat/reducer'

const reducers = combineReducers({
  menu,
  authUser,
  provinces,
  districts,
  branches,
  categories,
  address,
  cart,
  orders,
  complaint,
  chatApp,
})

export default reducers
