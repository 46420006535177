import {
  ADD_ADDRESS_ERROR,
  ADD_ADDRESS_START,
  ADD_ADDRESS_SUCCESS,
  DEFAULT_ADDRESS_ERROR,
  DEFAULT_ADDRESS_START,
  DEFAULT_ADDRESS_SUCCESS,
  DELETE_ADDRESS_ERROR,
  DELETE_ADDRESS_START,
  DELETE_ADDRESS_SUCCESS,
  EDIT_ADDRESS_ERROR,
  EDIT_ADDRESS_START,
  EDIT_ADDRESS_SUCCESS,
  GET_ADDRESS_ERROR,
  GET_ADDRESS_START,
  GET_ADDRESS_SUCCESS,
} from '../actions'

/**
 * Get Address
 */
export const getAddressStart = () => ({
  type: GET_ADDRESS_START,
})
export const getAddressSuccess = (address) => ({
  type: GET_ADDRESS_SUCCESS,
  payload: { address },
})
export const getAddressError = (error) => ({
  type: GET_ADDRESS_ERROR,
  payload: { error },
})

/**
 * Delete Address
 */
export const deleteAddressStart = (id) => ({
  type: DELETE_ADDRESS_START,
  payload: { id },
})
export const deleteAddressSuccess = (id) => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload: { id },
})
export const deleteAddressError = (error) => ({
  type: DELETE_ADDRESS_ERROR,
  payload: { error },
})

/**
 * Add Address
 */
export const addAddressStart = (address) => ({
  type: ADD_ADDRESS_START,
  payload: { address },
})
export const addAddressSuccess = (message) => ({
  type: ADD_ADDRESS_SUCCESS,
  payload: { message },
})
export const addAddressError = (error) => ({
  type: ADD_ADDRESS_ERROR,
  payload: { error },
})

/**
 * Edit Address
 */
export const editAddressStart = (address) => ({
  type: EDIT_ADDRESS_START,
  payload: { address },
})
export const editAddressSuccess = (message) => ({
  type: EDIT_ADDRESS_SUCCESS,
  payload: { message },
})
export const editAddressError = (error) => ({
  type: EDIT_ADDRESS_ERROR,
  payload: { error },
})

/**
 * Default Address
 */
export const defaultAddressStart = (id) => ({
  type: DEFAULT_ADDRESS_START,
  payload: { id },
})
export const defaultAddressSuccess = (message) => ({
  type: DEFAULT_ADDRESS_SUCCESS,
  payload: { message },
})
export const defaultAddressError = (error) => ({
  type: DEFAULT_ADDRESS_ERROR,
  payload: { error },
})
