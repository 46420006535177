import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { GET_CATEGORIES_START } from '../actions'
import { getCategoriesError, getCategoriesSuccess } from './actions'
import { apiClient } from '../../helpers/axios'
import notification from '../../helpers/notification'

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES_START, getCategories)
}

const getCategoriesAsync = () => {
  return apiClient.get('/ship-order-category/all')
}

function* getCategories() {
  try {
    const response = yield call(getCategoriesAsync)
    yield put(getCategoriesSuccess(response.data.data))
  } catch (error) {
    notification.danger(error.message)
    yield put(getCategoriesError(error.message))
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetCategories)])
}
